<template>
  <div>
    <a href="https://www.teamgather.co">
      <g-image :src="gatherLogo" alt="Gather logo" size="small" class="logo" />
    </a>
    <container>
      <grid two centered>
        <grid-column centered>
          <h1>Oops!</h1>
          <p>Looks like something went wrong.</p>
          <g-image
            :src="gif404"
            class="gif"
            alt="Girl dropping ball"
            rounded
            size="medium"
          />
          <btn class="btn" @click.native="$router.push({ name: 'moments' })">
            Take me to the home page
          </btn>
        </grid-column>
      </grid>
    </container>
  </div>
</template>

<script>
import gImage from '@/components/v2/image.vue'
import btn from '@/components/v2/btn.vue'
import container from '@/components/v2/container.vue'
import grid from '@/components/v2/grid/grid.vue'
import gridColumn from '@/components/v2/grid/column.vue'
import gif404 from '@/assets/img/oops.gif'
import gatherLogo from '@/assets/img/gather-logo-blue.png'

export default {
  components: {
    gImage,
    container,
    btn,
    grid,
    gridColumn,
  },
  data() {
    return {
      gif404,
      gatherLogo,
    }
  },
  watch: {
    $route: 'hideNavBar',
  },
  created() {
    this.hideNavBar()
  },
  methods: {
    hideNavBar() {
      this.$emit('changeNav', { type: 'noNavBar' })
    },
  },
}
</script>

<style lang="less" scoped>
.logo {
  margin: 1em;
}

.gif {
  margin: 0 auto;
}

.btn {
  margin: 1em 0 !important;
}
</style>
